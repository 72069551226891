<template>
    <div class="cip-content">
        <div class="card">
            <div class="card-body">
                <form>

                    <div class="form-group form-row">
                        <label for="section" class="col-sm-3 col-form-label">
                            {{ $t('customerdoc.section.name') }}
                        </label>
                        <div class="col-sm-6">
                            <select id="section" name="section"
                                    v-model="v$.section.$model"
                                    class="form-control" :class="{ 'invalid': v$.section.$error }"
                                    :disabled="readyForUpload">
                                <option/>
                                <option v-for="sec of sections" :key="sec.id" :value="sec">
                                    {{ sec.sectionName }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <span class="form-text error-msg" v-if="v$.section.$error">{{ v$.section.$errors[0].$message }}</span>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <label for="customer" class="col-sm-3 col-form-label">{{ $t('customer.name') }}</label>
                        <div class="col-sm-9">
                            <div class="row">
                                <div class="col-sm-8">  <!-- :disabled="readyForUpload" not working -->
                                    <vue-bootstrap-typeahead id="customer" name="customer"
                                                             v-model="customerFullTextSearch"
                                                             :data="customers"
                                                             :serializer="c => c.customerNo + ' ' + c.customerName"
                                                             @hit="customer = $event"
                                    />
                                    <!-- c.customerNo + ' ' + -->
                                    <!--div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="forallcust" v-model="forAllCustomers" :disabled="readyForUpload"
                                            @click="customer = null; customerFullTextSearch = null">
                                        <label class="form-check-label" for="forallcust">{{ $t('customerdoc.admin.visAll') }}</label>
                                    </div-->
                                </div>
                                <div class="col-sm-4">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <label for="fileDescr" class="col-sm-3 col-form-label">
                            {{ $t('customerdoc.admin.desc') }}
                        </label>
                        <div class="col-sm-6">
                            <input id="fileDescr" name="fileDescr" type="text"
                                   v-model="v$.fileDescr.$model"
                                   class="form-control" :class="{ 'invalid': v$.fileDescr.$error }"
                                   :disabled="readyForUpload">
                        </div>
                        <div class="col-sm-3">
                            <span class="form-text error-msg" v-if="v$.fileDescr.$error">{{ v$.fileDescr.$errors[0].$message }}</span>
                        </div>
                    </div>

                    <div class="form-group form-row" v-if="!readyForUpload">
                        <button class="btn btn-primary" :disabled="v$.$invalid" @click.prevent="activateUpload">
                            {{ $t('customerdoc.admin.openUpload') }}
                        </button>
                    </div>

                    <div class="form-group form-row" v-if="readyForUpload">
                        <label for="uploadDzone" class="col-sm-3 col-form-label">
                            {{ $t('customerdoc.document') }}
                        </label>
                        <div class="col-sm-6">
                            <vue-dropzone id="uploadDzone" name="uploadDzone" :options="uploadOptions"
                                          v-on:vdropzone-sending="onBeforeUploadDoc"
                                          v-on:vdropzone-success="onAfterUploadDocSuccess"
                                          v-on:vdropzone-error="onAfterUploadDocError">
                            </vue-dropzone>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-md-8">
                            <span class="error-msg">{{ errorMsg }}</span>
                        </div>
                    </div>

                    <button type="reset" class="btn-close" @click="$emit('cancelled')">
                        <font-awesome-icon icon="times"/>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import vue2Dropzone from 'vue2-dropzone'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import useVuelidate from '@vuelidate/core'
import {required} from '@/validation'
import cipAxios from '@/cipAxios'
import {getUrl} from '@/cipLib'

export default Vue.extend({
    name: 'customerDocAdminAdd',
    props: ['sections'],
    emits: ['doc-added', 'cancelled'],
    components: {
        vueDropzone: vue2Dropzone,
        VueBootstrapTypeahead,
    },

    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            section: null,
            customers: [],
            customerFullTextSearch: null,
            customer: null,
            fileDescr: '',
            uploadOptions: {
                url: getUrl('rest/admin/cusdoc'),
                withCredentials: true,
                //headers: {
                //},
                //thumbnailWidth: 150,
                //maxFilesize: 0.5,
            },
            readyForUpload: false,
            errorMsg: null,
            timer: null,
        }
    },
    validations() {
        return {
            section: {required},
            customer: {},
            fileDescr: {required},
        }
    },

    watch: {
        'customerFullTextSearch': function () {
            this.setCustomersWithTimeout()
        }
    },

    methods: {
        setCustomersWithTimeout() {
            //console.log('setCustomersWithTimeout')
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.setCustomers()
            }, 500);
        },

        setCustomers() {
            this.errorMsg = null
            return cipAxios.get('rest/customer', {
                params: {
                    fullTextSearch: this.customerFullTextSearch,
                }
            })
                .then(response => {
                    this.customers = response.data
                })
                .catch((errorMsg) => {
                    this.errorMsg = errorMsg
                })
        },

        async activateUpload() {
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                this.readyForUpload = true
            }
        },

        onBeforeUploadDoc(file, xhr, formData) {
            //console.log('uploading')
            formData.append('sectionId', this.section.id);
            formData.append('customerId', this.customer == null ? '' : this.customer.customerId);
            formData.append('fileDescr', this.fileDescr);
            formData.append('active', true);
        },

        onAfterUploadDocSuccess() {
            this.$emit('doc-added')
            this.v$.$reset()
        },

        onAfterUploadDocError() {
            this.errorMsg = 'Upload not successful'
        },

    }
})
</script>

<style scoped lang="scss">
div .card, div .cip-content-header-card {
    max-width: 1300px;
}

.visall {
    font-style: italic;
}
</style>
