<template>
    <div id="newsdetail" class="cip-content">

        <div class="card" id="newsDetailPanel">
            <div class="card-body">
                <form @submit.prevent="saveNewsDetails">

                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{{ $t('news.admin.visible') }}</label>
                        <div class="col-sm-3">
                            <font-awesome-icon icon="eye" v-if="isNewsVisible()"/>
                            <font-awesome-icon icon="eye-slash" v-else/>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <label for="publishingDate" class="col-sm-2 col-form-label">
                            {{ $t('news.admin.publishingDate') }}
                        </label>
                        <div class="col-sm-3">
                            <vuejs-datepicker id="publishingDate" name="publishingDate"
                                              v-model="puDate" format="dd.MM.yyyy"
                                              :bootstrap-styling="true" :clear-button="true"
                                              :input-class="{ 'invalid': v$.puDate.$error }">
                            </vuejs-datepicker>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <label for="publishingTo" class="col-sm-2 col-form-label">{{
                                $t('news.admin.publishingTo')
                            }}</label>
                        <div class="col-sm-3">
                            <vuejs-datepicker name="publishingTo" v-model="puTo" :bootstrap-styling="true"
                                              :clear-button="true" format="dd.MM.yyyy">
                            </vuejs-datepicker>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <label for="businessAreaCode"
                               class="col-sm-2 col-form-label">{{ $t('customer.businessArea.name') }}</label>
                        <div class="col-sm-3">
                            <select v-model="newsDetail.businessAreaCode" name="businessAreaCode"
                                    class="form-control bus-area">
                                <option></option>
                                <option v-for="busArea of visBusinessAreas" :value="busArea.code" :key="busArea.code">
                                    {{ busArea.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-check form-group form-row">
                        <!--label for="active" class="col-sm-2 col-form-label">Active</label-->
                        <div class="col-sm-3 offset-sm-2">
                            <input id="active" type="checkbox" v-model="newsDetail.active" class="form-check-input">
                            <label class="form-check-label" for="active">{{ $t('news.admin.active') }}</label>
                        </div>
                    </div>

                    <div v-for="newsText in newsDetail.texts" :key="newsText.langCode">
                        <div class="text-header">
                            <span class="language">{{ getLangName(newsText.langCode) }}</span>
                            <button class="btn" type="button" @click="deleteLanguage(newsText.langCode)">
                                <font-awesome-icon icon="trash-alt" v-if="newsText.langCode != 'EN'"/>
                            </button>
                        </div>

                        <NewsAdminDetailText :newsDetailId="newsDetail.id"
                                             :newsText="newsText"
                                             @news-text-changed="(nt) => setNewsText(newsText.langCode, nt)"
                                             :languages="languages"
                                             :initAdminDetailData="initAdminDetailData">
                        </NewsAdminDetailText>
                    </div>

                    <div class="form-row add-language-row">
                        <div class="col-sm-2">
                            <select v-model="langCodeToAdd" class="form-control">
                                <option :value="null"></option>
                                <option v-for="lng in addableLanguages" :key="lng.code" :value="lng.code">
                                    {{ lng.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn" type="button" @click="addLanguage()" :disabled="langCodeToAdd == null">
                                <font-awesome-icon icon="plus"/>
                            </button>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <div class="col-sm-1">
                            <button type="submit" class="btn btn-primary" :disabled="v$.$invalid">
                                {{ $t('label.save') }}
                            </button>
                        </div>
                        <div class="col-sm-1">
                            <button type="button" class="btn btn-light" @click="deleteNews()"
                                    v-if="newsDetail.id != null" :disabled="newsDetail.readByAnyUser">
                                {{ $t('label.delete') }}
                            </button>
                        </div>
                        <div class="col-sm-8 offset-sm-1">
                            <span :class="{'error-msg': isErrorMsg}" class="align-middle">{{ msg }}</span>
                        </div>
                    </div>

                    <button type="reset" class="btn-close" @click="$router.push('/admin/news')">
                        <font-awesome-icon icon="times"/>
                    </button>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@/validation'
import cipAxios, {handleHttpError} from '@/cipAxios'
import cipGraphQLClient from '@/cipGraphQLClient'
import * as cipDateLib from '@/cipDateLib'
import auth from '../../auth'
import vuejsDatepicker from 'vuejs-datepicker'
import NewsAdminDetailText from "@/components/news/NewsAdminDetailText"

export default {
    name: 'NewsAdminDetail',
    props: ['newsHeadId'],
    components: {
        vuejsDatepicker,
        NewsAdminDetailText,
    },

    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            languages: [],
            newsDetail: {
                id: null,
                publishingDate: null,
                publishingTo: null,
                businessAreaCode: null,
                readByAnyUser: false,
                texts: [],
                active: false
            },
            puDate: null,
            puTo: null,
            addableLanguages: [],
            langCodeToAdd: null,
            msg: "",
            isErrorMsg: false,
        }
    },
    validations() {
        return {
            puDate: {required},
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        visBusinessAreas() {
            return auth.buildVisBusinessAreas(this.user, this)
        },
    },
    created: function () {
        this.initAdminDetailData()
    },
    watch: {
        'newsHeadId': function () {
            this.initAdminDetailData()
        }
    },
    methods: {
        initAdminDetailData() {
            return cipAxios.get('rest/languages')
                .then(response => {
                    this.languages = response.data
                    //console.log('initData with newsHeadId', this.newsHeadId)
                    if (this.newsHeadId == 0) {
                        this.createNewsHead()
                    } else {
                        this.findNewsDetail()
                    }
                })
        },

        createNewsHead() {
            let tomorrow = new Date()
            tomorrow.setDate(new Date().getDate() + 1)
            this.puDate = tomorrow
            this.newsDetail.texts.push({
                id: null,
                langCode: 'EN',
                headerText: null,
                bodyText: null,
                fileAttached: false
            })
            this.newsDetail.active = true
            this.setAddableLanguages()
            //this.setAddableLanguages()
        },

        findNewsDetail() {
            this.clearMsg()
            const query =
                `query newsDetails($newsHeadId: Long!, $atDateTime: LocalDateTime!) {
                    newsHead(newsHeadId: $newsHeadId, atDateTime: $atDateTime) {
                        id
                        publishingDate
                        publishingTo
                        businessAreaCode
                        readByAnyUser
                        texts {
                            id
                            langCode
                            headerText
                            bodyText
                            fileAttached
                        }
                        active
                    }
                }`
            return cipGraphQLClient.request(query, {
                newsHeadId: this.newsHeadId,
                atDateTime: cipDateLib.createLocalDateTime(new Date()),
            })
                .then(data => {
                    this.newsDetail = data.newsHead
                    this.puDate = cipDateLib.createDate(this.newsDetail.publishingDate)
                    this.puTo = cipDateLib.createDate(this.newsDetail.publishingTo)
                    //this.textList = this.cvtToTextList(this.newsDetail.texts)
                    this.setAddableLanguages()
                })
                .catch(error => {
                    this.setErrorMsg(handleHttpError(error.response.status, error.response.error))
                })
        },

        setAddableLanguages() {
            let alngs = []
            for (let lng of this.languages) {
                let alreadyAdded = false
                for (let newsTxt of this.newsDetail.texts) {
                    if (lng.code == newsTxt.langCode) {
                        // already added -> skip language
                        alreadyAdded = true
                        break
                    }
                }
                if (!alreadyAdded)
                    alngs.push(lng)
            }
            this.addableLanguages = alngs
        },
        addLanguage() {
            this.clearMsg()
            if (this.langCodeToAdd == null)
                return
            this.newsDetail.texts.push({langCode: this.langCodeToAdd, id: null, headerText: null, bodyText: null})
            this.setAddableLanguages()
            this.langCodeToAdd = null
        },
        deleteLanguage(langCode) {
            this.clearMsg()
            if (langCode == null)
                return
            this.newsDetail.texts = this.newsDetail.texts.filter(nt => nt.langCode != langCode)
            this.setAddableLanguages()
        },
        getLangName(langCode) {
            for (let lng of this.languages) {
                if (lng.code == langCode)
                    return lng.name
            }
            return langCode
        },

        setNewsText(langCode, newsText) {
            for (const idx in this.newsDetail.texts) {
                if (this.newsDetail.texts[idx].langCode == langCode) {
                    //console.log(this.newsDetail.texts[idx]); console.log(newsText);
                    this.newsDetail.texts[idx] = newsText
                    return
                }
            }
        },
        isNewsVisible() {
            return this.newsDetail.active && cipDateLib.isInPeriod(new Date(), this.puDate, this.puTo)
        },

        async saveNewsDetails() {
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                this.clearMsg()
                // convert entry fields back to newsDetail
                this.newsDetail.publishingDate = cipDateLib.createLocalDate(this.puDate)
                this.newsDetail.publishingTo = cipDateLib.createLocalDate(this.puTo)

                if (this.newsDetail.id == null) {
                    cipAxios.post('rest/admin/news', this.newsDetail)
                        .then(response => {
                            let loca = response.headers['location']
                            //console.log('Location', loca)
                            if (loca != null) {
                                let ll = loca.split('/')
                                let newsHeadId = ll[ll.length - 1]
                                this.$router.push(newsHeadId)
                                this.setInfoMsg(this.$t('label.stored'))
                            }
                        })
                } else {
                    cipAxios.put('rest/admin/news/' + this.newsDetail.id, this.newsDetail)
                        .then(() => {
                            this.findNewsDetail()
                            this.setInfoMsg(this.$t('label.stored'))
                        })
                }
            }
        },

        deleteNews() {
            this.clearMsg()
            cipAxios.delete('rest/admin/news/' + this.newsDetail.id)
                .then(() => {
                    this.$router.push('/admin/news')
                })
        },

        clearMsg() {
            this.msg = ""
            this.isErrorMsg = false
        },
        setInfoMsg(msg) {
            this.msg = msg
            this.isErrorMsg = false
        },
        setErrorMsg(msg) {
            this.msg = msg
            this.isErrorMsg = true
        },

    }
}
</script>

<style scoped lang="scss">
@import '../../variables';

div .card {
    min-width: 1365px;
}

input[name="publishingDate"], input[name="publishingTo"] {
    background-color: white !important;
}

.bus-area {
    text-transform: uppercase;
}

.text-header {
    background-color: $color-light-grey;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: calc(0.25rem - 1px);
}

.add-language-row {
    background-color: $color-light-grey;
    padding: 5px;
    margin-bottom: 15px;
    border-radius: calc(0.25rem - 1px);
}

.language {
    font-weight: bold;
}
</style>
