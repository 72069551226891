<template>
<div class="cip-content">
    <div class="cip-content-header-card">
        <div class="card-header" style="text-transform: uppercase">
            {{ $t('customerdoc.admin.name') }}
        </div>
    </div>

    <CustomerDocAdminAdd v-if="addDoc"
                            :sections="sections"
                            @doc-added="() => {this.addDoc = false; this.setDocs()}"
                            @cancelled="() => {this.addDoc = false}">
    </CustomerDocAdminAdd>

    <div class="card" v-if="!addDoc">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <select v-model="filterSection" class="form-control" @change="setDocs()">
                        <option />
                        <option v-for="sec of sections" :value="sec" :key="sec.id">{{ sec.sectionName }}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <input type="text" v-model="filterFullText" class="form-control" @keyup="setDocsWithTimeout()">
                </div>

                <!--div class="col-md-2">
                    <button class="btn btn-primary" @click="setDocs()">Search</button>
                </div-->

                <div class="offset-md-4 col-md-2">
                    <button class="btn btn-primary" @click="addDoc = true">{{ $t('customerdoc.admin.add') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-9">
                    <span class="error-msg">{{ errorMsg }}</span>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!addDoc">
      <div class="card" v-for="doc of docs" :key="doc.id">
        <div class="card-body row">
            <div class="col-md-1">
                <button type="button" class="btn btn-light" v-if="editDocId == null" @click="startEditDoc(doc)">
                    <font-awesome-icon icon="edit"/>
                </button>
                <button type="button" class="btn btn-light" v-if="editDocId == doc.id" @click="updateDoc(doc.id); editDocId = null">
                    <font-awesome-icon icon="save"/>
                </button>
            </div>
            <div class="col-md-1">
                <button type="button" class="btn btn-light" v-if="editDocId == null" @click="deleteDoc(doc.id)">
                    <font-awesome-icon icon="trash-alt"/>
                </button>
                <button type="button" class="btn btn-light" v-if="editDocId == doc.id" @click="resetDoc()">
                    <font-awesome-icon icon="times"/>
                </button>
            </div>
            <div class="col-md-2">
                <select name="section" v-model="doc.section" class="form-control" v-if="editDocId == doc.id">
                    <option v-for="sec of sections" :key="sec.id" :value="sec" >{{ sec.sectionName }}</option>
                </select>
                <span class="form-control-plaintext" v-else>{{ doc.section.sectionName }}</span>
            </div>
            <div class="col-md-2">
                <span class="form-control-plaintext" v-if="doc.customer != null">{{ doc.customer.customerNo +' '+ doc.customer.customerName }}</span>
                <span class="form-control-plaintext visall" v-else>{{ $t('customerdoc.admin.visAll') }}</span>
            </div>
            <div class="col-md-2">
                <input type="text" v-model="doc.fileDescr" v-if="editDocId == doc.id" />
                <span class="form-control-plaintext" v-else>{{ doc.fileDescr }}</span>
            </div>
            <div class="col-md-2">
                <span class="form-control-plaintext">{{ doc.fileName }}</span>
            </div>
            <div class="col-md-1">
                <a class="btn btn-light" :href="getCustomerDocURL(doc.id)">
                    <font-awesome-icon icon="file-alt" />
                </a>
            </div>
            <div class="col-md-1">
                <div class="form-check" v-if="editDocId == doc.id">
                    <input type="checkbox" v-model="doc.active" class="form-check-input" id="actdc">
                </div>
                <div v-else>
                    <font-awesome-icon icon="eye" v-if="doc.active"/>
                    <font-awesome-icon icon="eye-slash" v-else/>
                </div>
            </div>
            <div class="col-md-1">
                <span class="form-control-plaintext">
                <!--button type="button" class="btn btn-light" v-if="editDocId == null" @click="moveSection(sec.id, 'up')">
                    <font-awesome-icon icon="sort-up"/>
                </button>
                <button type="button" class="btn btn-light" v-if="editDocId == null" @click="moveSection(sec.id, 'down')">
                    <font-awesome-icon icon="sort-down"/>
                </button-->
                </span>
            </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import cipAxios from '@/cipAxios'
import { getUrl } from '@/cipLib'
import CustomerDocAdminAdd from './CustomerDocAdminAdd'

export default Vue.extend({
    name: 'customerDocAdmin',
    components: {
        CustomerDocAdminAdd
    },

    data () {
        return {
            sections: [],
            filterSection: null,
            filterFullText: null,
            docs: [],
            editDocId: null,
            addDoc: false,
            errorMsg: null,
            timer: null,
        }
    },

    created: function() {
        this.setSections()
        .then(() => this.setDocs())
    },

    methods: {
        setSections() {
            return cipAxios.get('rest/admin/cusdoc/sec')
            .then(response => {
                this.sections = response.data
            })
        },

        setDocsWithTimeout() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.setDocs()
            }, 500);
        },

        setDocs() {
            this.errorMsg = null
            return cipAxios.get('rest/admin/cusdoc', {
                params: {
                    fullTextSearch: this.filterFullText,
                    // maybe support customerId but customer is containted in full-text-search also
                    sectionId: this.filterSection == null ? null : this.filterSection.id,
                }
            })
            .then(response => {
                this.docs = response.data
            })
            .catch((errorMsg) => {
                this.errorMsg = errorMsg
            })
        },

        startEditDoc(doc) {
            // change edit mode
            this.editDocId = doc.id
        },

        resetDoc() {
            this.editDocId = null
            // reload to skip changed data
            this.setSections()
            .then(() => this.setDocs())
        },

        getCustomerDocURL(customerDocId) {
            return getUrl('rest/admin/cusdoc/' + customerDocId + '/file')
        },

        updateDoc(docId) {
            let doc = this.docs.find(dc => dc.id == docId)
            //console.log('doc', doc)
            cipAxios.put('rest/admin/cusdoc/' + docId, doc)
            .then(() => {
                this.setDocs()
            })
            .catch((errorMsg) => {
                this.errorMsg = errorMsg
            })
        },

        deleteDoc(docId) {
            cipAxios.delete('rest/admin/cusdoc/' + docId)
            .then(() => {
                this.setDocs()
            })
            .catch((errorMsg) => {
                this.errorMsg = errorMsg
            })
        },

    }
})
</script>

<style scoped lang="scss">
div .card, div .cip-content-header-card {
    max-width: 1300px;
}

.visall {
    font-style: italic;
}
</style>
