<template>
<div class="cip-content">
    <!--div class="cip-content-header-card">
        <div class="card-header" style="text-transform: uppercase">
            {{ $t('openitem.admin.name') }}
        </div>
    </div-->

	<div class="card" style="min-width: 1500px">
		<div class="card-body">
            <!--div class="row">
                        Open Item Administration
            </div-->

            <div class="form-group row">
                <label class="col-md-2 col-form-label">{{ $t('mill.name') }}</label>
                <div class="col-md-2">
                    <select v-model="millId" class="form-control">
                        <option value="100">Unterkochen</option>
                        <option value="103">Tolosa</option>
                        <option value="107">Arches</option>
                        <option value="108">Dettingen</option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">{{ $t('openitem.admin.reference') }}</label>
                <div class="col-md-2">
                    <input type="text" v-model="invoiceNoPattern" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Customer Name</label>
                <div class="col-md-2">
                    <input type="text" v-model="customerNamePattern" class="form-control">
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <button type="button" class="btn btn-primary" :disabled="millId == null" @click="getAdditionalOpenItems()">
                        {{ $t('label.search') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="card" v-if="addopenitems.length > 0" style="min-width: 1500px">
        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th class="label">{{ $t('mill.name') }}</th>		<!-- span  th:text="#{KIS.LBL_CALLOFF_CUST_ORDER_POS_NO}"/ -->
                        <th class="label">{{ $t('openitem.admin.reference') }}</th>
                        <th class="label">{{ $t('customer.no') }}</th>
                        <th class="label">{{ $t('customer.name') }}</th>
                        <th class="label">{{ $t('openitem.admin.docDate') }}</th>
                        <th class="label">{{ $t('openitem.admin.netDueDate') }}</th>
                        <th class="label">{{ $t('openitem.admin.amountLocal') }}</th>
                        <th class="label">{{ $t('openitem.admin.localCurr') }}</th>
                        <th class="label">{{ $t('openitem.admin.amount') }}</th>
                        <th class="label">{{ $t('openitem.admin.curr') }}</th>
                        <th class="label">{{ $t('openitem.admin.cleared') }}</th>
                        <th class="label">{{ $t('openitem.admin.changeTime') }}</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="oi in addopenitems" :key="oi.id">
                        <td class="data">{{ oi.millName }}</td>
                        <td class="data">{{ oi.paymentDocNo }}</td>
                        <td class="data">{{ oi.millCustomerNo }}</td>
                        <td class="data">{{ oi.customerName }}</td>
                        <td class="data">{{ oi.postingDate | cipFormatDate}}</td>
                        <td class="data">{{ oi.dueDate | cipFormatDate}}</td>
                        <td class="data"><input type="number" step="0.01" v-model="oi.paymentDocGrossAmountLocal" @change="onChangePaymentGrossDocAmountLocal(oi.id, oi.paymentDocGrossAmountLocal)"></td>
                        <td class="data">{{ oi.localCurrency }}</td>
                        <td class="data"><input type="number" step="0.01" v-model="oi.paymentDocGrossAmount" @change="onChangePaymentGrossDocAmount(oi.id, oi.paymentDocGrossAmount)"></td>
                        <td class="data">{{ oi.paymentDocCurrency }}</td>
                        <td class="data"><input type="checkbox" v-model="oi.cleared" @change="onChangeCleared(oi.id, oi.cleared)"></td>
                        <td class="data">{{ oi.changeDate | cipFormatDateTime }}</td>
                    </tr>
                </tbody>
            </table>

            <div>{{ message }}</div>
        </div>
		</div>
</div>
</template>

<script>
import cipAxios from '@/cipAxios'

export default {
  name: 'open-item-admin',
	data () {
		return {
			millId: 103,
			invoiceNoPattern: '',
			customerNamePattern: '',
			addopenitems: [],
			message: '',
		}
	},
	
	methods: {
		getAdditionalOpenItems: function() {
			var vm = this;
			vm.message = '';
			cipAxios.get('rest/admin/openitem', {
				params: { 
					millId: this.millId,
					invoiceNoPattern: this.invoiceNoPattern,
					customerNamePattern: this.customerNamePattern,
				},
			})
			.then(function(response) {
					vm.addopenitems = response.data;
					if (vm.addopenitems.length == 0) {
						vm.message = 'no open items found';
					}
			})
		},
		getAdditionalOpenItemsOnReturn: function(event) {
			//console.log("getAdditionalOpenItemsOnReturn", event);
			if (event.which == 13) {
				this.getAdditionalOpenItem();
			}
		},
		onChangeCleared: function(id, cleared) {
			var vm = this;
			cipAxios.put('rest/admin/openitem/' + id, null, {
				params: {
					cleared: cleared
				}
			})
			.then(function() {
				vm.getAdditionalOpenItems();
			})			
		},
		onChangePaymentGrossDocAmountLocal: function(id, paymentDocGrossAmountLocal) {
			var vm = this;
			cipAxios.put('rest/admin/openitem/' + id, null, {
				params: {
					paymentDocGrossAmountLocal: paymentDocGrossAmountLocal
				}
			})
			.then(function() {
				vm.getAdditionalOpenItems();
			})
		},
		onChangePaymentGrossDocAmount: function(id, paymentDocGrossAmount) {
			var vm = this;
			cipAxios.put('rest/admin/openitem/' + id, null, {
				params: {
					paymentDocGrossAmount: paymentDocGrossAmount
				}
			})
			.then(function() {
				vm.getAdditionalOpenItems();
			})
		},
	},
}
</script>
