<template>
<div id="news" class="cip-content">
    <div class="cip-content-header-card">
        <div class="card-header" style="text-transform: uppercase">
            {{ $t('news.admin.name')}}
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="offset-md-10 col-md-2">
                    <button class="btn btn-primary" @click="$router.push('/admin/news/0')">{{ $t('news.admin.add') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-9">
                    <span class="error-msg"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="card" v-if="newsOverviewList.length == 0">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-9">
                    <span class="error-msg">{{ $t('news.notfound') }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="card" v-for="news in newsOverviewList" :key="news.id">
        <div class="card-body">
            <div class="container-fluid m-0 p-0">
                <div class="row">
                    <router-link :to="'/admin/news/' + news.id" class="col-md-11 btn stretched-link" id="dtllnk">
                        <div class="row">
                            <div class="col-3">
                                {{ news.publishingDate | cipFormatDate }}
                                <span v-if="news.publishingTo != null">
                                    - {{ news.publishingTo | cipFormatDate }}
                                </span>
                            </div>
                            <div class="col-6">
                                <span v-if="news.text4User != null">{{ news.text4User.headerText }}</span>
                            </div>
                            <div class="col-2">
                                <span class="bus-area">{{ getBusinessAreaName(news.businessAreaCode) }}</span>
                            </div>
                            <div class="col-1">
                                <font-awesome-icon icon="eye" v-if="isNewsVisible(news)"/>
                                <font-awesome-icon icon="eye-slash" v-else/>
                            </div>
                        </div>
                    </router-link>
                    <div class="col-md-1">
                        <a class="btn btn-light" :href="getAdminAttachmentURL(news.id, news.attachedFileLangCode4User)" v-if="news.attachedFileLangCode4User != null">
                            <font-awesome-icon icon="file-alt" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" v-if="nextCursor != null">
        <div class="card-body">
            <div class="row justify-content-md-center">
                <div class="col-md-2">
                    <button type="button" class="btn btn-light" @click="getNewsOverviewList(false)">{{ this.$t('label.loadMore') }}</button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import cipGraphQLClient from '@/cipGraphQLClient'
import cipAxios, { handleHttpError } from '@/cipAxios'
import * as cipDateLib from '@/cipDateLib'
import auth from '../../auth'
import { getUrl } from '@/cipLib'

export default {
    name: 'news-admin',
    data () {
        return {
            newsOverviewList: [],
            nextCursor: null,
        }
    },
    created: function() {
        this.getNewsOverviewList(true)
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        visBusinessAreas() {
            return auth.buildVisBusinessAreas(this.user, this)
        },
    },
    methods: {
        getNewsOverviewList(firstPage) {
            const query =
                `query newsList($atDateTime: LocalDateTime!, $pageSize: Int, $nextCursor: Cursor) {
                    newsListForAdmin(atDateTime: $atDateTime, pageSize: $pageSize, nextCursor: $nextCursor) {
                        page {
                            id
                            publishingDate
                            publishingTo
                            businessAreaCode
                            text4User {
                                langCode
                                headerText
                                fileAttached
                            }
                            attachedFileLangCode4User
                            active
                        },
                        nextCursor
                    }
                }`
            return cipGraphQLClient.request(query , {
                atDateTime: cipDateLib.createLocalDateTime(new Date()),
                pageSize: 20,
                nextCursor: firstPage ? null : this.nextCursor
            })
            .then(data => {
                this.newsOverviewList = firstPage ? data.newsListForAdmin.page : this.newsOverviewList.concat(data.newsListForAdmin.page)
                this.nextCursor = data.newsListForAdmin.nextCursor
            })
            .catch(error => {
                handleHttpError(error.response.status, error.response.error)
            })
        },

        getNewsOverviewListREST() {
            cipAxios.get('rest/admin/news')
            .then(response => {
                this.newsOverviewList = response.data
            })
        },

        getBusinessAreaName(code) {
            let ba = this.visBusinessAreas.find(ba => ba.code === code)
            return ba == undefined ? '' : ba.name
        },

        isNewsVisible(newsOvEntry) {
            return newsOvEntry.active && cipDateLib.isInPeriod(new Date(), newsOvEntry.publishingDate, newsOvEntry.publishingTo)
        },

        getAdminAttachmentURL(newsHeadId, langCode) {
            return getUrl('rest/news/' + newsHeadId + '/' + langCode + '/attachment')
        },
    }
}
</script>

<style lang="scss" scoped>
div .card, div .cip-content-header-card {
    max-width: 1300px;
}

.bus-area {
    text-transform: uppercase;
}

#dtllnk {
    padding-top: 6px;
    padding-bottom: 6px;
}
</style>
