<template>
    <vue-editor name="bodyText"
                v-model="editorText" @text-change="setText(editorText)"
                :customModules="customModulesForEditor"
                :editorOptions="editorSettings"
                :editor-toolbar="customToolbar">
    </vue-editor>
    <!--vue-editor name="bodyText" v-model="newsText.bodyText"
        :customModules="customModulesForEditor" :editorOptions="editorSettings" :editor-toolbar="customToolbar">
    </vue-editor-->
    <!--vue-editor name="bodyText" :editorOptions="editorSettings" v-model="newsText.bodyText"></vue-editor-->
    <!--vue-editor name="bodyText" v-model="newsText.bodyText"></vue-editor-->
</template>

<script>
import {VueEditor/*, Quill*/} from 'vue2-editor'
import {ImageDrop} from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize'

//Quill.register('modules/imageDrop', ImageDrop)
//Quill.register('modules/imageResize', ImageResize)

/* TODO check if english text defined
extend('englishTextDefined', {
    validate(langCodeList) {
        console.log('langCodeList', langCodeList)
        return langCodeList.find(elem => elem == 'en') != null
    }
})
*/
/*
function rgbComponentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + rgbComponentToHex(r) + rgbComponentToHex(g) + rgbComponentToHex(b);
}

const colorsToHex = [
    rgbToHex(0,64,56), rgbToHex(56,36,36), rgbToHex(28,47,69), rgbToHex(235,194,0), rgbToHex(83,14,56), rgbToHex(2,116,86), rgbToHex(81,53,53),
    rgbToHex(50,83,122), rgbToHex(150,26,103), rgbToHex(104,46,108), rgbToHex(240,83,35), rgbToHex(221,210,205), rgbToHex(60,60,60),
]
*/

const colors = [
    'rgb(0,64,56)', 'rgb(56,36,36)', 'rgb(28,47,69)', 'rgb(235,194,0)', 'rgb(83,14,56)', 'rgb(2,116,86)', 'rgb(81,53,53)',
    'rgb(50,83,122)', 'rgb(150,26,103)', 'rgb(104,46,108)', 'rgb(240,83,35)', 'rgb(221,210,205)', 'rgb(60,60,60)', 'rgb(255,255,255)',
    'rgb(0,0,0)', 'rgb(255,255,255)', 'rgb(192,192,192)', 'rgb(128,128,128)', 'rgb(64,64,64)',
]

//var Size = Quill.import('attributors/style/size');
//Quill.register(Size, true);


export default {
    name: 'NewsAdminTextEditor',
    props: ['text', 'setText'],

    data() {
        return {
            editorText: this.text,
            customModulesForEditor: [
                {alias: 'imageDrop', module: ImageDrop},
                {alias: 'imageResize', module: ImageResize},
            ],
            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {}
                }
            },
            customToolbar: [
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                //[{ 'size': ['small', 'false', 'large', 'huge'] }],
                //[{ 'size': ['12px', '16px', '18px'] }],
                ['bold', 'italic', 'underline'],
                [{align: []}],
                //[{ 'direction': 'rtl' }],   // text direction ... same as align-left/right?
                [{list: 'ordered'}, {list: 'bullet'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'color': colors}, {'background': colors}],
                [{'script': 'sub'}, {'script': 'super'}],
                //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                //[{ 'header': 1 }, { 'header': 2 }],
                ['link', 'image', 'video'],
                //['blockquote', 'code-block'],
                //['clean']   // remove formatting
            ],
        }
    },
    components: {
        VueEditor
    },
}
</script>

<style scoped lang="scss">
</style>
