<template>
<div class="cip-content">
    <div class="cip-content-header-card">
        <div class="card-header" style="text-transform: uppercase">
            {{ $t('customerdoc.section.admin.name') }}
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="offset-md-10 col-md-2">
                    <button class="btn btn-primary" @click="addSection()" :disabled="editSectionId != null">{{ $t('customerdoc.section.admin.add') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-9">
                    <span class="error-msg">{{ errorMsg }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="card" v-for="sec of sections" :key="sec.id">
        <div class="card-body row">
            <div class="col-md-1">
                <button type="button" class="btn btn-light" v-if="editSectionId == null" @click="startEditSection(sec)">
                    <font-awesome-icon icon="edit"/>
                </button>
                <button type="button" class="btn btn-light" v-if="editSectionId == sec.id" @click="updateSection(sec.id); editSectionId = null">
                    <font-awesome-icon icon="save"/>
                </button>
            </div>
            <div class="col-md-1">
                <button type="button" class="btn btn-light" v-if="editSectionId == null && sec.docCount == 0" @click="deleteSection(sec.id)">
                    <font-awesome-icon icon="trash-alt"/>
                </button>
                <button type="button" class="btn btn-light" v-if="editSectionId == sec.id" @click="resetSection()">
                    <font-awesome-icon icon="times"/>
                </button>
            </div>
            <div class="col-md-8">
                <div v-for="secLang of sec.sectionLangs" class="row form-group" :key="secLang.langCode">
                    <label class="col-md-2 col-form-label">{{ getLangName(secLang.langCode) }}</label>
                    <div class="col-md-10">
                        <input type="text" v-model="secLang.sectionName" class="form-control" v-if="editSectionId == sec.id">
                        <span class="form-control-plaintext" v-else>{{ secLang.sectionName }}</span>
                    </div>
                </div>
                <div class="row" v-if="editSectionId == sec.id">
                    <select v-model="langCodeToAdd" class="form-control col-md-2" @change="addLanguage(sec.id, langCodeToAdd)">
                        <option :value="null"></option>
                        <option v-for="lng in addableLanguages" :key="lng.code" :value="lng.code">
                            {{ lng.name }}
                        </option>
                    </select>
                    <!--button type="button" class="btn btn-light col-md-1 offset-md-1" @click="addLanguage(sec.id, langCodeToAdd)" :disabled="langCodeToAdd == null">
                        <font-awesome-icon icon="plus"/>
                    </button-->
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-check" v-if="editSectionId == sec.id">
                    <input type="checkbox" v-model="sec.active" class="form-check-input" id="actcb">
                    <label class="form-check-label" for="actcb">active</label>
                </div>
                <div v-else>
                    <font-awesome-icon icon="eye" v-if="sec.active"/>
                    <font-awesome-icon icon="eye-slash" v-else/>
                </div>
            </div>
            <div class="col-md-1">
                <button type="button" class="btn btn-light" v-if="editSectionId == null" @click="moveSection(sec.id, 'up')">
                    <font-awesome-icon icon="sort-up"/>
                </button>
                <button type="button" class="btn btn-light" v-if="editSectionId == null" @click="moveSection(sec.id, 'down')">
                    <font-awesome-icon icon="sort-down"/>
                </button>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import Vue from 'vue'
import cipAxios from '@/cipAxios'

export default Vue.extend({
    name: 'customerDocSectionAdmin',
    data () {
        return {
            sections: [],
            languages: [],
            editSectionId: null,
            addableLanguages: [],
            langCodeToAdd: null,
            errorMsg: null,
        }
    },

    created: function() {
        this.setSections()
        .then(this.setLanguages())
    },

    methods: {
        setLanguages() {
            return cipAxios.get('rest/languages')
            .then(response => {
                this.languages = response.data
            })
        },

        setSections() {
            this.errorMsg = null
            return cipAxios.get('rest/admin/cusdocsec')
            .then(response => {
                this.sections = response.data
            })
        },

        startEditSection(section) {
            this.setAddableLanguages(section.id)
            // change edit mode
            this.editSectionId = section.id
        },

        resetSection() {
            this.editSectionId = null
            this.setSections()
        },

        addSection() {
            let newSection = {
                id: 0,
                sectionLangs: [{langCode: 'EN', sectionName: ''}],
                seqNo: null,
                active: false,
            }
            this.sections.push(newSection)
            this.startEditSection(newSection)
        },

        updateSection(secId) {
            let section = this.sections.find(section => section.id == secId)
            cipAxios.put('rest/admin/cusdocsec/' + secId, section)
            .then(() => {
                this.setSections()
            })
            .catch((errorMsg) => {
                this.errorMsg = errorMsg
            })
        },

        moveSection(secId, direction) {
            cipAxios.put('rest/admin/cusdocsec/' + secId + '/move/' + direction)
            .then(() => {
                this.setSections()
            })
        },

        setAddableLanguages(secId) {
            let sectionLangs = this.sections.find(sec => sec.id == secId).sectionLangs
            let alngs = []
            for (let lng of this.languages) {
                let alreadyAdded = false
                for (let secLng of sectionLangs) {
                    if (lng.code == secLng.langCode) {
                        // already added -> skip language
                        alreadyAdded = true
                        break
                    }
                }
                if (!alreadyAdded)
                    alngs.push(lng)
            }
            this.addableLanguages = alngs
        },

        addLanguage(secId, langCode) {
            let sectionLangs = this.sections.find(sec => sec.id == secId).sectionLangs
            sectionLangs.push({langCode: langCode, sectionName: ''})
            this.setAddableLanguages(secId)
            this.langCodeToAdd = null
        },

        getLangName(langCode) {
            for (let lng of this.languages) {
                if (lng.code == langCode)
                    return lng.name
            }
            return langCode
        },

        deleteSection(secId) {
            cipAxios.delete('rest/admin/cusdocsec/' + secId)
            .then(() => {
                this.setSections()
            })
            .catch((errorMsg) => {
                this.errorMsg = errorMsg
            })
        },

    }
})
</script>
