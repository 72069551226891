<template>
<div id="news" class="cip-content">
    <div class="card">
        <iframe id="iframe" :src="src"></iframe>
    </div>
</div>
</template>

<script>
import CipIFrame from '../CipIFrame'
import { getUrl } from '../../cipLib'

export default {
  name: 'scheduler-job-list',
  mixins: [CipIFrame],
  data () {
    return {
      src: '',
    }
  },

  methods: {
    setSrc() {
      this.src = getUrl('Main?NAVPOINT=ADMIN.SCHED_JOB_LIST&MODULE=ADMIN&ACTION=SCHED_JOB_LIST')
    }
  }
}
</script>

<style scoped lang="scss">
div .card {
    max-width: 1400px;
}
</style>

