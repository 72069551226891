<template>
    <div>
        <div class="form-group form-row">
            <label for="headerText" class="col-sm-2 col-form-label">
                {{ $t('news.admin.header') }}
            </label>
            <div class="col-sm-10">
                <input id="headerText" name="headerText" type="text"
                       v-model="headerText"
                       @change="emitNewsTextChange"
                       class="form-control" :class="{ 'invalid': v$.headerText.$error }">
            </div>
        </div>

        <div class="form-group form-row">
            <label for="bodyText" class="col-sm-2 col-form-label">{{ $t('news.admin.body') }}</label>
            <div class="col-sm-10">
                <NewsAdminTextEditor id="bodyText" name="bodyText"
                                     :text="bodyText"
                                     :setText="(txt) => {bodyText = txt; emitNewsTextChange()}">
                </NewsAdminTextEditor>
            </div>
        </div>

        <div class="form-group form-row" v-if="newsText.id != null">
            <label class="col-sm-2 col-form-label">{{ $t('news.admin.attachment') }}</label>
            <div class="col-sm-1">
                <a :href="getAdminAttachmentURL(newsDetailId, newsText.langCode)" v-if="newsText.fileAttached"
                   class="btn btn-light">
                    <font-awesome-icon icon="file-alt"/>
                </a>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn btn-light" v-if="!newsText.fileAttached"
                        @click="openUploadPanel(newsDetailId, newsText)">
                    <font-awesome-icon icon="plus"/>
                </button>
                <button type="button" class="btn btn-light" v-else
                        @click="openUploadPanel(newsDetailId, newsText)">
                    <font-awesome-icon icon="exchange-alt"/>
                </button>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn btn-light" v-if="newsText.fileAttached"
                        @click="deleteAttachment(newsDetailId, newsText.langCode)">
                    <font-awesome-icon icon="trash-alt"/>
                </button>
            </div>
        </div>

        <div class="card" id="uploadPanel" v-if="uploadPanelActive">
            <div class="card-body">
                <label for="uploadAtt" class="col-sm-3 col-form-label">
                    {{ $t('news.admin.attachment') }} {{ $t('news.admin.forLanguage') }}
                    <span class="language">{{ getLangName(uploadForNewsText.langCode) }}</span>
                </label>
                <vue-dropzone id="uploadDzone" ref="uploadDzone"
                              :options="uploadOptions"
                              @vdropzone-success="() => closeUploadPanel()">
                </vue-dropzone>

                <button type="reset" class="btn-close" @click="closeUploadPanel()">
                    <font-awesome-icon icon="times"/>
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@/validation'
import NewsAdminTextEditor from '@/components/news/NewsAdminTextEditor'
import {getUrl} from "@/cipLib"
import cipAxios from "@/cipAxios"
import vue2Dropzone from "vue2-dropzone"

/* TODO check if english text defined
extend('englishTextDefined', {
    validate(langCodeList) {
        console.log('langCodeList', langCodeList)
        return langCodeList.find(elem => elem == 'en') != null
    }
})
*/

export default {
    name: 'NewsAdminDetailText',
    props: ['newsDetailId', 'newsText', 'languages', 'initAdminDetailData'],
    emits: ['news-text-changed'],
    components: {
        NewsAdminTextEditor,
        vueDropzone: vue2Dropzone,
    },

    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            headerText: this.newsText.headerText,
            bodyText: this.newsText.bodyText,
            fileAttached: this.newsText.fileAttached,
            //
            uploadPanelActive: false,
            uploadForNewsText: null,
            uploadOptions: {
                url: '',
                withCredentials: true,
                //thumbnailWidth: 150,
                //maxFilesize: 0.5,
            },
        }
    },
    validations() {
        return {
            headerText: {required, $autoDirty: true}
        }
    },
    methods: {
        emitNewsTextChange() {
            let nt = Object.assign({}, this.newsText);
            nt.headerText = this.headerText
            nt.bodyText = this.bodyText
            nt.fileAttached = this.fileAttached
            this.$emit('news-text-changed', nt)
        },
        getLangName(langCode) {
            for (let lng of this.languages) {
                if (lng.code == langCode)
                    return lng.name
            }
            return langCode
        },
        getAdminAttachmentURL(newsHeadId, langCode) {
            return getUrl('rest/news/' + newsHeadId + '/' + langCode + '/attachment')
        },
        deleteAttachment(newsHeadId, langCode) {
            cipAxios.delete('rest/admin/news/' + newsHeadId + '/' + langCode + '/attachment')
                .then(() => {
                    this.initAdminDetailData()
                })
        },
        openUploadPanel(newsHeadId, newsText) {
            this.uploadPanelActive = true
            this.uploadForNewsText = newsText
            // TODO use setOptions
            this.uploadOptions.url = getUrl('rest/admin/news/' + newsHeadId + '/' + newsText.langCode + '/attachment')
        },
        closeUploadPanel() {
            this.uploadPanelActive = false
            this.uploadForNewsText = null
            this.uploadOptions.url = ''
            this.initAdminDetailData()
        },
    }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.language {
    font-weight: bold;
}
</style>
